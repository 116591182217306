import React, { useContext, useEffect } from "react";
import { Row, Col, Card, Divider } from "antd";
import GoogleIcon from "../../assets/img/gmailIcon.svg";
import { useHistory, Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { Helmet } from "react-helmet";
import AOS from "aos";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

import authBg1 from "../../assets/img/auth/corn-on-the-cob-2083529_1280.jpg";
import authBg2 from "../../assets/img/auth/BB760D04-0DC4-4349-BFD1-641529BE0A78.jpg";
import authBg3 from "../../assets/img/auth/WheatVarietyTrials_HeadedGreen.jpg";
import authBg4 from "../../assets/img/auth/image_22.jpg";
import BackgroundSlider from "react-background-slider";
import { AppContext } from "../../appRouter";
import { ToastContainer, toast } from "react-toastify";
import mapImage from "../../assets/img/home/main/map.png";
import logo from "../../assets/img/home/main/logo.png";

import { HeaderLogo } from "../../styles/home/HeaderStyles";
const AuthLayout = (props) => {
  const { children, style, fadeName, googleLabel, pageTitle, oAuth2 } = props;
  const history = useHistory();
  const { location } = history;
  const { handleIsLoggedIn } = useContext(AppContext);

  useEffect(() => {
    AOS.init({ duration: 600 });
  }, []);

  const bgImages = [authBg1, authBg2, authBg3, authBg4];

  const onSuccess = async (response) => {
    let userDetails = {
      email: response?.profileObj?.email,
      first_name: response?.profileObj?.givenName,
      last_name: response?.profileObj.familyName,
    };

    let res = await oAuth2(userDetails);

    if (res.success) {
      toast("Successfully Logged In..redirecting to dashboard", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleIsLoggedIn(true);
      setTimeout(() => {
        history.push("/dashboard");
      }, 2000);
    } else {
      // this backend team confused the auth endpoints
      if (
        res.message.non_field_errors[0] ===
        "Unable to log in with provided credentials."
      ) {
        toast.error("Unable to log in with provided credentials.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An unexpected error occurred. Kindly try again, please.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      handleIsLoggedIn(false);
    }
  };

  const onFailure = (response) => {
    // toast.error('An unexpected error occurred. Kindly try again, please.', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    // });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Helmet>
        <title>{pageTitle} | Login</title>
      </Helmet>
      <Row
        style={{
          backgroundImage: `url(${mapImage})`,
          boxShadow: "inset 2000px 0 0 0 rgba(0, 15, 11, 0.9)",
        }}
      >
        {/* <Col xs={24} xl={12} lg={12}>
          <div className="left-side">
            <BackgroundSlider images={bgImages} duration={5} transition={2} />
            <div className="overlay"></div>
            <div className="left-content">
              <h2>Manage your fields remotely</h2>
              <p>
                Monitor the state of your crops right from the office, learn
                about the slightest changes on-the-spot, and make fast and
                reliable decisions on field treatment
              </p>
            </div>
          </div>
        </Col> */}
        <Col style={{ maxWidth: "50%", margin: "auto" }}>
          <div className="right-side">
            <div className="auth-form" style={{ style }}>
              <Card
                bordered={false}
                className="card-shadow"
                data-aos={fadeName}
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                {location.pathname === "/auth/login" ||
                location.pathname === "/auth/login/" ||
                location.pathname === "/auth/register" ||
                location.pathname === "/auth/register/" ? (
                  <>
                    <div
                      className="auth_page_writeup"
                      style={{ color: "#011C14" }}
                    >
                      <a
                        href="https://www.agroprever.com/"
                        style={{
                          marginBottom: "2em",
                          background:
                            "linear-gradient(88.57deg, #011811 20.69%, #253631 74.52%)",
                          padding: "5%",
                          borderRadius: "50%",
                        }}
                      >
                        <HeaderLogo src={logo}  />
                      </a>
                      {/* <h2>Welcome Back</h2> */}
                      <p style={{fontSize: '20px', lineHeight: '26px', marginTop: '2%'}}>
                        Manage mutiple farms and farmers, and make smarter decisions with our realtime and easy to use farm management platform
                      </p>
                    </div>
                    <Row gutter={24}>
                      {/* <Col xs={24} xl={24} lg={24}>
                        <GoogleLogin
                          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                          render={(renderProps) => (
                            <button
                              disabled={renderProps.disabled}
                              onClick={renderProps.onClick}
                              className="g-login"
                            >
                              <img src={GoogleIcon} alt="gooleicon" />
                              <span>{googleLabel} with Google</span>
                            </button>
                          )}
                          buttonText="Login"
                          onSuccess={onSuccess}
                          onFailure={onFailure}
                          cookiePolicy={"single_host_origin"}
                        />
                      </Col> */}
                    </Row>
                    <Divider>OR</Divider>
                  </>
                ) : (
                  ""
                )}
                {children}
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AuthLayout;
